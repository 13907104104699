<template>
  <div>
    <el-dialog
      title="消费级别设置"
      :visible.sync="showDialog"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="input-box df-row">
          <div class="df-col">
            <span>级别名称：</span>
            <el-input
              style="width: 180px;"
              v-model="submitData.RangeName"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="df-col">
            <span>级别范围：</span>
            <el-input
              style="width: 100px;"
              v-model="submitData.AmountFrom"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
            <span style="margin-inline: 10px;">至</span>
            <el-input
              style="width: 100px;"
              v-model="submitData.AmountTo"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="df-col">
            <el-button
              type="primary"
              size="mini"
              :disabled="addDisabled"
              @click="onAddRange"
              >添加</el-button
            >
          </div>
        </div>
        <el-table
          :data="rangeList"
          border
          stripe
          size="mini"
          max-height="400px"
          v-loading="loading"
        >
          <el-table-column prop="RangeName" label="级别名称"> </el-table-column>
          <el-table-column prop="AmountFrom" label="开始范围">
          </el-table-column>
          <el-table-column prop="AmountTo" label="结束范围"> </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="onEditRange(scope)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="rangeList.splice(scope.$index, 1)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveParamMemberBasicClassStatis"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="编辑消费级别"
      :visible.sync="levelShow"
      width="640px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="levelShow = false"
    >
      <span class="content-box">
        <div class="input-box df-row">
          <div class="df-col">
            <span>级别名称：</span>
            <el-input
              style="width: 180px;"
              v-model="selectItem.RangeName"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="df-col">
            <span>级别范围：</span>
            <el-input
              style="width: 100px;"
              v-model="selectItem.AmountFrom"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
            <span style="margin-inline: 10px;">至</span>
            <el-input
              style="width: 100px;"
              v-model="selectItem.AmountTo"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
          </div>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="levelShow = false">取 消</el-button>
        <el-button type="primary" @click="onEditRangeSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Report from "@/api/report.js";

export default {
  data() {
    return {
      selectIndex: -1,
      selectItem: {
        RangeId: "",
        RangeName: "",
        AmountFrom: "",
        AmountTo: "",
      },
      submitData: {
        RangeId: "",
        RangeName: "",
        AmountFrom: "",
        AmountTo: "",
      },
      rangeList: [],
      showDialog: false,
      levelShow: false,
      loading: false,
      addDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 获取级别
    async fetchParamMemberBasicClassStatis() {
      try {
        let { data } = await Report.fetchParamMemberBasicClassStatis();
        this.rangeList = data.RangeList;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存级别
    async saveParamMemberBasicClassStatis() {
      try {
        let { errcode } = await Report.saveParamMemberBasicClassStatis({
          RangeList: this.rangeList
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 添加级别
    onAddRange() {
      let submitData = JSON.parse(JSON.stringify(this.submitData)),
        rangeList = JSON.parse(JSON.stringify(this.rangeList)),
        nowTime = new Date().getTime();

      if (!submitData.RangeName) {
        this.$message.error("请输入级别名称");
        return;
      } else if (!submitData.AmountFrom) {
        this.$message.error("请输入开始范围");
        return;
      } else if (!submitData.AmountTo) {
        this.$message.error("请输入结束范围");
        return;
      }
      this.addDisabled = true;
      submitData.RangeId = nowTime.toString();

      rangeList.push(submitData);
      this.rangeList = rangeList;
      setTimeout(() => {
        this.addDisabled = false;
      }, 600);
      console.log(this.rangeList);
    },

    // 编辑级别
    onEditRange(event) {
      let item = JSON.parse(JSON.stringify(event.row)),
        index = JSON.parse(JSON.stringify(event.$index));
      this.selectIndex = index;
      this.selectItem = item;
      this.levelShow = true;
    },

    // 保存修改级别数据
    onEditRangeSave() {
      let selectItem = JSON.parse(JSON.stringify(this.selectItem)),
        rangeList = JSON.parse(JSON.stringify(this.rangeList));

      if (!selectItem.RangeName) {
        this.$message.error("请输入级别名称");
        return;
      } else if (!selectItem.AmountFrom) {
        this.$message.error("请输入开始范围");
        return;
      } else if (!selectItem.AmountTo) {
        this.$message.error("请输入结束范围");
        return;
      }
      rangeList[this.selectIndex] = selectItem;
      this.rangeList = rangeList;
      this.levelShow = false;
    },

    // 重置数据
    resetData() {
      this.submitData = {
        RangeId: "",
        RangeName: "",
        AmountFrom: "",
        AmountTo: "",
      };
    },

    // 打开对话框
    onShowDialog() {
      this.fetchParamMemberBasicClassStatis();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .input-box {
    .df-col {
      .flex-row;
    }
  }
}
</style>
